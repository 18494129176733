@import url('https://fonts.googleapis.com/css2?family=Mada:wght@200..900&display=swap');


@font-face {
  font-family: Gthin;
  src: url(../src/font/Gellix-Thin.woff);
}

@font-face {
  font-family: Gregular;
  src: url(../src/font/Gellix-Regular.woff);
}

@font-face {
  font-family: Gmedium;
  src: url(../src/font/Gellix-Medium.woff);
}

@font-face {
  font-family: Gsemibold;
  src: url(../src/font/Gellix-SemiBold.woff);
}

@font-face {
  font-family: Gbold;
  src: url(../src/font/Gellix-Bold.woff);
}

@font-face {
  font-family: Gextrabold;
  src: url(../src/font/Gellix-ExtraBold.woff);
}

html {
  scroll-behavior: smooth;
}
div#root {
  height: 100vh;
}
body {
  background: 
  #111111;
  font-family: "Mada", sans-serif !important;
}
.read-article-paragraph p span{
  font-family: "Mada", sans-serif !important;
}
.row {
  margin: 0;
  padding: 0;
}
input{
  padding: 16px 20px;
background: unset !important;
border: 1px solid rgba(46, 52, 51, 0.2);
border-radius: 5px;
outline: none;
}
.PhoneInputCountry {
  margin-left: 14px;
}
.PhoneInputInput {
  border: none !important;
  height: 60px;
  outline: none !important;
}
.PhoneInputInput :focus {
  outline: none !important;
  border: none !important;
}
.PhoneInput {
  border: 1.5px solid #EAEAEA;
  border-radius: 8px;
}
button{
  cursor: pointer;
  outline: none !important;
}
.p-0 {
  padding: 0px;
}
.MuiTypography-colorTextSecondary {
  color: #e43c3c!important;
}
.p0 {
  padding: 0px;
}
.form-control {
  border: 1.5px solid #EAEAEA; 
  min-height: 60px;
}
.country-name {
  margin-top: -3px;
  margin-left: 4px;
}
.country {
  display: flex;
  align-items: center;
}
.intl-tel-input .country-list .country .dial-code {
  margin-top: -3px !important;
}
.dial-code {
  margin-top: 0px;
}
.flag-container {
  width: 100% !important;
}
.one-input{
  width: 100% !important;
}
.allow-dropdown {
  width: 100%;
}
.text-danger{
  color: #E40536 !important;
padding-top: 20px;
font-size: 16px !important;
font-style: normal;
font-weight: 900 !important;
line-height: 100%; /* 16px */
}
.intl-tel-input {
  width: 100%;
}
// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}
textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

  
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  div,p,h1,h2,h3,h4,h5,h6 {
    margin: 0 0;
  }
  
  // h1 {
  //   font-size: 55px !important;
  //   font-style: normal !important;
  

  // }

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

a,.hasv{
  text-decoration: none!important;
}
h1 {
  font-size: 55px !important;
  font-style: normal !important;
}
.grey {
  color: #888 !important;
}
.grey2 {
  color: #475569;
}
.ptb20 {
  padding: 20px 0;
}
p {
  font-size: 16px ;
}
.btn-common {
  background: #FF0083;
  box-shadow: 0px 28px 30px -10px rgba(255, 0, 131, 0.15);
  border-radius: 100px;
  white-space: nowrap;
  font-weight: 700;
  
  position: absolute;
  right: 179px;
  top: 37px;
  right: -1037px;
  z-index: 9999;
  top: -7px;
  font-style: normal;
  font-size: 18px;
  line-height: 100%;
  color: #FFFFFF !important;
  outline: none;
  padding: 10px 16px;

  border: 1px solid #FF0083;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    background-color: transparent;
    border: 1px solid #FF0083;
    color: #FF0083;
}
}

.ptb {
  padding: 60px 0;
}

.btn-common2 {
  background: transparent;
  border-radius: 4px;
  margin: 10px 0;
  padding: 8px 10px;
  font-family: "Barlow", serif;
  color: #ff0083;
  border: 1px solid #ff0083;
  text-decoration: none;
  font-size: 13px;
  text-transform: uppercase;
  &:hover {
    color: #ff0083 !important;
    box-shadow: 0px 28px 30px -10px rgba(255, 0, 131, 0.15);
    border: 1px solid #ff0083;
    background-color: #fff;
  }
  &:active {
    color: #ff0083 !important;
    // box-shadow: 0px 28px 30px -10px rgba(255, 0, 131, 0.15);
    background-color: #fff;
  }
  &:focus {
    outline: none;
  }
}

.btn-red {
  background: #f41e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff !important;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #f41e5e !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
  }
}
.btn-white {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  color: #aa3935 !important;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #fff !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #aa3935;
  }
}
.modal-dialog .modal-body .main-heading .crossimg {
  position: absolute;
  top: -48px !important;
  right: -3px !important;
 
}
.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}
.yellow {
  color: #ffc13d;
}
.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}
.darkgrey {
  color: #414141 !important;

}

.red {
  color: #f41e5e;
}
.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}

.card-style {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;

  .btn-common {
    width: 100%;
  }

  .inner-user {
    margin: 10px 0px;
  }
  .time {
    background: #fafafa;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 0;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
  }

  h5 {
    margin: 5px 0px 5px;
  }

  h6 {
    font-size: 13px;
  }
}
.brdr-all {
  width: 100%;
  background-color: #e2e8f0;
  margin: 30px 0;
  height: 1px;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 1.5rem !important;
  }

  h2 {
    // font-size: 1.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;
      h5 {
        font-weight: 500;
      }
      li {
        margin-right: 2px !important;
      }
    }
  }
}
// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }
  .right-side {
    margin-top: 30px;
    h5 {
      font-weight: 500;
    }
    li {
      margin-right: 8px !important;
    }
  }

  h1 {
    font-size: 3rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 20px 0;
  }
}
// 12 pro max
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }

  .right-side {
    margin-top: 30px;
    h5 {
      font-weight: 500;
    }
    li {
      margin-right: 8px !important;
    }
  }
  h1 {
    font-size: 3rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 20px 0;
  }
  .modal-dialog .modal-body .main-heading .crossimg {
    position: absolute;
    top: -48px !important;
    right: -3px !important;
   
}
  iframe {
    position: unset !important;
    top: 0px !important;
    left: 0px !important;
    width: 0px !important;
    height: 0px !important;
  }

}

  @media (max-width: 600px) {
  
    .allheading{
      font-size: 30px!important;
    }

  }
 
iframe{
  position: unset!important;
  top: unset!important;
  left: unset!important;
  width: unset!important;
  height: unset!important;
  border: unset!important;
  z-index: unset!important;
}

.MuiBackdrop-root{
  z-index: 111!important;
}


.shdshhgd{
  width: 150px!important;
  height: 150px;
}
.card-heading-4{
  h5{
    color: #B6B6B6;font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 16px */
  }
}
.Toastify__toast-container{
  top: 100px!important;
}
.modal-backdrop{
  z-index: 999999;
}
.modal{
  z-index: 2000000;
}

.read-article-paragraph{
  list-style: none
}

.navbar-toggler:focus{
  box-shadow: none;
}


.cursorrr{
  cursor: pointer;
}
