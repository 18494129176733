.main-submit .main-inputs .inputmain .buttn .dropmain{
    height: 300px;
    overflow: scroll;
}


.main-submit {
    // padding-top: 160px;
    min-height: 100vh;

    .proposalbgimg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .buttn{
        label{
            color: #FFF !important;
        }
    }

    .innersubmit{
        padding: 58px 60px;
        border-radius: 30px;
border: 1px solid #FF0083;
background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
backdrop-filter: blur(35.099998474121094px);
margin-bottom: 120px;
    }


    .btnnnss {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        .arbtn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 40px;
            background: #ff0083;
            border-radius: 5px;
            border: none;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            color: #FFFFFF;
            border: 1px solid transparent;
            margin-right: 10px;

            &:hover {
                border: 1px solid #ff0083;
                color: #ff0083;
                background-color: transparent;
            }
        }
    }

    .topTicket {
        padding: 16px 20px;
        background: #ff0083;
        border-radius: 5px;
        width: 221px;
        justify-content: space-between;

        color: #ffffff;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
    }

    .main-inputs {
        .unlimited {
            height: 212px;
            background: transparent;
            border-radius: 20px;
            border: 1px solid #FF0083;
            padding: 19px;
            overflow-y: scroll;
            margin-bottom: 20px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .topmt {
            margin-top: 20px;
        }

        .upperhead {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .custom-control-label::before {
                width: 20px;
                height: 20px;
                background: transparent;
                border: 1.5px solid #FF0083;
                border-radius: 5px;
            }

            .custom-control-label::after {
                top: 4px;
                left: 1px;
            }

            .custom-control-input:focus~.custom-control-label::before {
                box-shadow: none !important;
            }

            .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
                background-color: #FF0083;
                border-radius: 5px;
                width: 20px;
                height: 20px;
                background-image: url(/Assets/Vector.svg);
            }

            .head {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 100%;
                text-align: left;
                color: #fff;
            }
        }

        .inputmain {

            .hinput {
                height: 137px;
                background: transparent;
                border: 1px solid #E2E2E2;
                border-radius: 10px;
                padding: 18px;
                margin-bottom: 16px;

                &:focus-visible {
                    outline: none !important;
                }

                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    color: #C8C8C8;
                }
            }

            .buttn {
                .dropbtn {
                    background: transparent;
                    border-radius: 39px;
                    border: 1px solid var(--Accent, #FF0083);
                    width: 100%;
                    padding: 10px 12px 10px 24px;
                    max-width: 530px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    color: #C8C8C8;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;

                    .text-dark{
                        color: #C8C8C8 !important;
                    }

                    .imgbtn {
                        width: 24px;
                        height: 24px;
                        transition: all 0.7s ease; /* Transition effect lagaya */

                    }

                    &:focus {
                        box-shadow: none;
                    }

                    &:active {
                        color: #C8C8C8 !important;
                    }

                    &:focus-within {
                        color: #C8C8C8 !important;
                    }

                }

                .dropmain1{
                    width: 100%;
                    height: unset;
                    overflow: scroll;
                    width: 100%;
                    padding: 15px 18px 5px;
                    background: #151515;
                    border: 1px solid var(--Accent, #FF0083) !important;
       margin-top: 15px;
                    border-radius: 10px;
                    z-index: 999999;
               cursor: pointer;

                    .item1 {
                        border-bottom: 1px solid var(--Accent, #FF0083) !important;
                        padding: 0px;
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                        color: #fff !important;

                        &:active {
                            background-color: transparent;
                        }

                        &:hover {
                            background-color: transparent;
                        }
                        &:last-child {
                            border-bottom: none !important; // Border remove kar diya gaya hai
                        }
                    }
                }
                ::-webkit-scrollbar{
                    display: none;
                }
                .dropmain {
                    width: 100%;
                    padding: 15px 18px 5px;
                    background: #151515;
    border-radius: 20px;
    border: 1px solid #FF0083;
    z-index: 999;
    height: unset !important;
    margin-top: 10px;
margin-bottom: 10px;
overflow: scroll;

.item1 {
    border-bottom: 1px solid #FF0083;
    padding: 0px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    color: #fff;

    &:active {
        background-color: transparent;
    }

    &:hover {
        background-color: transparent;
    }

    &:last-child {
        border-bottom: none; // Border remove kar diya gaya hai
    }
}
                }
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                color: #fff;
                margin-bottom: 10px;
            }

            input {
                border-radius: 39px;
                border: 1px solid var(--Accent, #FF0083);
                max-width: 530px;
                width: 100%;
    padding: 10px 12px 10px 24px;
    background-color: transparent;
    outline: none;
    color: var(--White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
                color: #fff !important;
    
                margin-bottom: 20px;
    
                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    color: #c8c8c8;
                }
            }
        }
    }

    .main-heading {
        h2 {
            color: var(--White, #FFF);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; 
            margin-bottom: 20px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: #FFF;
            margin-bottom: 10px;
        }

        input {
            border-radius: 39px;
            border: 1px solid var(--Accent, #FF0083);
            max-width: 530px;
            width: 100%;
padding: 10px 12px 10px 24px;
background-color: transparent;
outline: none;
color: var(--White, #FFF);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%;
            color: #fff !important;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                color: #c8c8c8;
            }
        }
    }

    .textareadescription {
        margin-top: 20px;
        margin-bottom: 10px;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #FFF;
            margin-bottom: 10px;
        }

        .productarea {
                border-radius: 39px;
                border: 1px solid var(--Accent, #FF0083);
                max-width: 530px;
                width: 100%;
    padding: 10px 12px 10px 24px;
    background-color: transparent;
    outline: none;
    color: var(--White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
                color: #fff !important;
    
                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    color: #c8c8c8;
                
            }
        }
    }

    .enddate {
        position: relative;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px !important;
            line-height: 140%;
            color: #FFF;
            margin-bottom: 10px;
        }

        input {
            border-radius: 39px;
            border: 1px solid var(--Accent, #FF0083);
            max-width: 530px;
            width: 100%;
padding: 10px 12px 10px 24px;
background-color: transparent;
outline: none;
color: var(--White, #FFF);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%;
            color: #fff !important;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                color: #c8c8c8;
            }
        }

        .imagess {
            position: relative;
            display: inline;

            img {
                position: absolute;
                top: 0px;
                right: 10px;
            }

            input[type="date"]::-webkit-calendar-picker-indicator {
                cursor: pointer;
                opacity: 1;
                display: block;
                background: url('../../Assets/calendar-2.svg') no-repeat;
                width: 20px;
                height: 20px;
            }
        }
    }

    .submitbutton {
        button {
            margin-top: 20px;
            align-items: center;
            padding: 20px 113px;
            background: #ff0083;
            border-radius: 30px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            text-align: center;
            color: #ffffff;
            padding: 20px;
            border: none;
            margin-top: 30px;
            margin-bottom: 100px;
            width: 100%;
        }
    }
    .rdw-editor-main{
        height: 166px;
        // padding-top: 20px;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    .active-content{
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        a{
            margin-right: 12px;
            &:last-child{
                margin-right: 0;
            }
        }
        button{
            border-radius: 130px;
            border: 1px solid #FF0083;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 100%; 
background-color: transparent;
            &:last-child{
                margin-right: 0;
            }
            .change-color-svg path{
                fill: #FFF;
            }
           
        }
        .active-btn-show{
            border-radius: 130px;
            border: 1px solid #FF0083;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 100%; 
background: #FF0083;
        }
        .stroke-set path{
            fill: unset !important;
            stroke: #FF0083;
        }
        
    }
}

.confirmmodal {
    
    .modal-dialog {
        .modal-content {
            border-radius: 15px;
            border: 1px solid rgba(255, 255, 255, 0.10);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(120, 120, 120, 0.03) 100%);
            backdrop-filter: blur(80px);
        }

        .modal-body {
            .main-heading {
                position: relative;
                border-radius: 10px;
                border: 1px solid rgba(255, 255, 255, 0.10);
                padding: 20px;

                h3 {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; /* 25.2px */
                    text-align: start;
                }

                .crossimg {
                    position: absolute;
                    top: 16px !important;
                    right: 16px !important;
                    opacity: unset !important;
                }
            }

            .success {
                text-align: center;
                margin-top: 44px;
                margin-bottom: 15px;

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    color: #ffff;
                    margin-bottom: 39px;
                }
            }

            .confirmbtn {
                text-align: center;

                button {
                    background: #ff0083;
                    border-radius: 45px;
                    padding: 14px 100px;
                    align-items: center;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    text-align: center;
                    color: #ffffff;
                    border: none;
                    cursor: pointer;
                    // width: 449px;
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            .Submitproposal {
                text-align: center;

                h6 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: 0.02em;
                    color: #171515;
                    margin-top: 105px;
                    margin-bottom: 101px;
                }
            }

            .balance {
                text-align: center;
                margin-top: 92px;

                h6 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: 0.02em;
                    color: #171515 !important;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;

                    letter-spacing: 0.02em;

                    color: #f21d1d;
                    margin-top: 5px;
                    margin-bottom: 87px;
                }
            }
            .upper-img{
                margin: 30px 0;
            }
            .submitfee-text{
                h6{
                    color: #BCBCBC;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 22.4px */
                    margin-bottom: 5px;

                }
                h4{
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                span{
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                button{
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                    background: #FF0083;
                    border-radius: 45px;
                    padding: 17px;
                    border: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.rdw-editor-main {
    // background-color: #fafafa;
    background-color: #fff;
    border: 1px solid #e8e7e7;
    border-radius: 6px;
    padding-left: 14px;
    padding-right: 14px;
}

.error {
    color: #f21d1d !important;
}

.close {
    opacity: unset !important;
}
.buttn.show {

    .dropbtn {
        color: #393939 !important;

        .imgbtn {
            transform: rotate(180deg);
            transition: 0.3s;
        }
    }
}

@media (max-width:991px) {
    .main-submit .main-inputs .upperhead .custom-control-label::after {
        left: 0px;
    }
}

.submit-simple{
    background: #FF0083;
    border-radius: 10px;
    padding: 20px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
    width: 100%;
}

.toast-success{
    .fade.show{
        border-radius: 9px;
        border: none;
    }
    border-radius: 9px;
    border: none;
   .toast-header{
    background: #6AC259;
    border-radius: 9px;
    justify-content: space-between;
    padding: 15px;
    position: relative;
    .btn-close{
        background: url("../../Assets/toast-close.svg");
        position: absolute;
        top: 10px;
        right: 15px;
        z-index: 999;
        opacity: 1;
    }
    .parent{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .left{
            margin-right: 10px;
        }
        .right{
            h6{
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                color: #FFFFFF;
                margin-bottom: 6px;
            }
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 100%;
                color: #FFFFFF;
            }
        }
    }
   }
}
.toast-error{
    .fade.show{
        border-radius: 9px;
        border: none;
    }
    border-radius: 9px;
    border: none;
    .toast-header{
     background: #F05228;
     border-radius: 9px;
     justify-content: space-between;
     padding: 15px;
     position: relative;
     .btn-close{
         background: url("../../Assets/toast-close.svg");
         position: absolute;
         top: 10px;
         right: 15px;
         z-index: 999;
         opacity: 1;
     }
     .parent{
         display: flex;
         align-items: center;
         justify-content: flex-start;
         .left{
             margin-right: 10px;
         }
         .right{
             h6{
                 font-style: normal;
                 font-weight: 700;
                 font-size: 18px;
                 line-height: 100%;
                 color: #FFFFFF;
                 margin-bottom: 6px;
             }
             p{
                 font-style: normal;
                 font-weight: 400;
                 font-size: 14px;
                 line-height: 100%;
                 color: #FFFFFF;
             }
         }
     }
    }
 }
 .toast-warning{
    .fade.show{
        border-radius: 9px;
        border: none;
    }
    .toast-header{
     background: #FFCC4D;
     border-radius: 9px;
     justify-content: space-between;
     padding: 15px;
     position: relative;
     border: none;
     .btn-close{
         background: url("../../Assets/toast-close.svg");
         position: absolute;
         top: 10px;
         right: 15px;
         z-index: 999;
         opacity: 1;
     }
     .parent{
         display: flex;
         align-items: center;
         justify-content: flex-start;
         .left{
             margin-right: 10px;
         }
         .right{
             h6{
                 font-style: normal;
                 font-weight: 700;
                 font-size: 18px;
                 line-height: 100%;
                 color: #FFFFFF;
                 margin-bottom: 6px;
             }
             p{
                 font-style: normal;
                 font-weight: 400;
                 font-size: 14px;
                 line-height: 100%;
                 color: #FFFFFF;
             }
         }
     }
    }
 }

 .my-dp{
    margin-bottom: 20px;
    .dropdown-toggle{
        background: transparent;
        border: 2px solid #ff0083;
        padding: 15px;
        border-radius: 10px;
        margin: 0 auto;
        display: block;
    }
 }

 @media(max-width:991px){
    .main-submit .active-content{

        gap: 20px;
    }
    .main-submit .innersubmit {
        padding: 25px 25px;
        border-radius: 30px;
        border: 1px solid #FF0083;
        background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0) 110.97%), rgba(29, 29, 29, 0.05);
        -webkit-backdrop-filter: blur(35.0999984741px);
        backdrop-filter: blur(35.0999984741px);
        margin-bottom: 120px;
    }
    .main-submit .main-heading h2 {
        color: #FFF;
        color: var(--White, #FFF);
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 20px;
    }
 }


 @media (max-width:600px){
    
    .main-submit .active-content a{
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100%;
    }
    .main-submit .active-content button:last-child{
        width: 100%;
    }
    .set-sm-text{
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
        width: 100%;
    }
    .main-submit .active-content{
        gap: 0px;
        flex-direction: column;
    }
 }







 .surediv{
    color: #FFF;
text-align: center;
font-size: 24px !important;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 24px */
padding-bottom: 10px;
 }
 .mydvvvvvv{
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgba(240, 240, 240, 0.10);
    padding: 20px;
    justify-content: space-between;
    margin-top: 25px;

 }
 .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
    backdrop-filter: blur(10px);
}
.modal {
    z-index: 2000000;
    backdrop-filter: blur(10px);
}
.public-DraftStyleDefault-ltr {
  margin: 0px !important;
  padding-top: 20px !important;
}