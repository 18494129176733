.navbar {
    position: relative;
    a{
        text-decoration: none;
    }

    .shdvs{
        display: flex;
        flex-direction: row;
        z-index: 9999;
    }


    .pinknavbtn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 10px;
        z-index:999;
        background: #FF0083;
        box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
        border-radius: 35px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
        border: none;
        padding: 11px;
        margin-right: 10px;
        border: 1px solid transparent;
    

        .btnimage {
            width: 34px;
            height: 34px;
            margin-right: 10px;
            border-radius: 50%;
        }

        &:hover{
            color: #FF0083;
            background-color: transparent;
            border: 1px solid #FF0083;
            text-decoration: none !important;
        }
    }

    .ahsbahsba {
        position: absolute;
        height: 100%;
        left: 50%;
        top: 40px;
        transform: translateX(-50%);

        img {
            transition: transform 0.6s ease 0s;
            width: 185px;
            transform: scale(1) translateY(92px);
        }
    }
}

.main-navbar .navbar-nav .dropdown .dropdown-menu li a:hover {
    color: #000;
    border-radius: 8px;
}

.ashvshsv {
    text-decoration: none !important;
    border: none !important;
}

.signxs {
   font-weight: 600;
    font-style: normal;
    // font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #FF0083;
    background-color: transparent;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    border: none !important;
}

.main-navbar .navbar-nav .dropdown .dropdown-menu li a {
    border-radius: 8px;
}

.button-hedaer {
    transition: .4s ease-in-out;
    background: #FF0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    width: 139px;
    height: 48px;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #FF0083;
   font-weight: 600;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    cursor: pointer;
    margin-left: 41px;

    &:hover {
        background-color: transparent;
        border: 1px solid #FF0083;
        color: #FF0083;
    }
}

.main-navbar {
    // transition: .4s ease-in-out;
  
    top: 65px;
    height: 125px;
    border-bottom: 1px solid transparent;
    z-index: 50;
    padding-left: 0px;
    transition: height 0.2s ease 0s;
    background: transparent;
    max-width: 330px;
    right: 0px;

    .navbar {
        padding: 28px 0px 20px 0px;
    }

    .navbar-nav {
        .nav-link {
            transition: .4s ease-in-out;
            font-weight: 500;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;
            padding: 0.5rem 0px;
            color: #9D9D9D;
            margin-right: 40px;
            border: 2px solid transparent;

            &:active {
                color: #000;
            }

            &:hover {
               font-weight: 600;
                font-style: normal;
                color: #000000;
                font-size: 16px;
                line-height: 110%;
                border-bottom: 2px solid #FF0083;
            }
        }

        li {
            a.active {
                display: block;
                padding: 0.5rem 0px;
               font-weight: 600;
                font-style: normal;
                text-decoration: none !important;
                color: #000000;
                font-size: 16px;
                line-height: 110%;
                // border-bottom: 2px solid #FF0083;
                margin-right: 40px;
                border-bottom: 2px solid #FF0083;
            }
        }
    }

    .btn-1 {
        margin-right: 25px;
        background: transparent !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #FF0083;
        cursor: pointer;
    }

    .btn-2 {
        cursor: pointer;
    }

    .hbhcdbh {
        padding-left: 11px;
    }
}

.togg i {
    color: #000000 !important;
}

.main-navbar .nav-item {
    margin-bottom: 8px !important;
}

// .navbar-expand-xl .navbar-collapse{
//     display: flex!important;
//     justify-content: space-between;
//     align-items: center;

// }

.main-navbar-mobile {
    transition: .4s ease-in-out;
    z-index: 999999;
    max-width: 330px;
    height: 106px;
  
    right: 0;
    transition: height 0.2s ease 0s;
    border-bottom: 1px solid #ece8e8;
    border: none;

    .navbar-nav .dropdown .dropdown-menu li a {
        border-radius: 8px;
    }

    .ahsbahsba {
        img {
            transform: scale(0.8) translateY(45px);
        }
    }

    background: transparent;

    .navbar {
        padding: 22px 0px 20px 0px;
    }

    // .navbar .ahsbahsba{
    //     top: 54%;
    // }
    .nav-item {
        margin-bottom: 8px !important;
    }

    .navbar-nav {
        .nav-link {
            transition: .4s ease-in-out;
            font-weight: 500;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;
            padding: 0.5rem 0px;
            color: #9D9D9D;
            margin-right: 40px;
            border: 2px solid transparent;

            &:active {
                color: #000;
            }

            &:hover {
               font-weight: 600;
                font-style: normal;
                color: #000000;
                font-size: 16px;
                line-height: 110%;
                border-bottom: 2px solid #FF0083;
            }
        }

        .nav-link.active {
            transition: .4s ease-in-out;
            display: block;
            padding: 0.5rem 0px;
           font-weight: 600;
            font-style: normal;
            text-decoration: none !important;
            color: #000000;
            font-size: 16px;
            line-height: 110%;
            // border-bottom: 2px solid #FF0083;
            margin-right: 40px;
            border-bottom: 2px solid #FF0083;
        }
    }

    .btn-1 {
        margin-right: 25px;
        background: transparent !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #FF0083;
        cursor: pointer;
    }

    .btn-2 {
        cursor: pointer;
    }

    .hbhcdbh {
        padding-left: 11px;
    }
}

.navbar-toggler {
    outline: none !important;

    .togg {
        i {
            color: #FF0083 !important;
        }
    }
}

.modalsecurity .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.modalsecurity .PrivateNotchedOutline-root-1 {
    padding-left: 8px;
    border: 1.5px solid #EAEAEA;
    border-radius: 10px;
}

.modalsecurity .ForInput input {
    padding: 14px 20px !important;
    background: #FFFFFF;
    border: 1.5px solid #EAEAEA;
    border-radius: 10px;

    &::placeholder {
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
        line-height: 130%;
        color: #a3a1a1;
    }
}

.sdhfvshgdfvsdhj {
    background: transparent;
    border-radius: 4px;
    border: 1px solid #ff0083;
    width: 140px;
    margin-left: 12.5px;
    height: 40px;
    margin-right: 20px;
    color: #ff0083;
    display: flex;
    text-decoration: none !important;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 100%;
    cursor: pointer;

    &:hover {
        background: #ff0083;
        border: 1px solid #ff0083;
        color: #ffffff !important;
    }
}

.ajnbdsajdbnsj {
    display: flex;
    justify-content: end;
    align-items: center;

    .kk {
        i {
            font-size: 28px;
            margin-right: 16px;
            color: #FF0083;
        }
    }

    .LL {
        i {
            font-size: 28px;
            color: #FF0083;
        }
    }
}

.modalsecurity .MuiOutlinedInput-input {
    border: 1px solid rgb(190, 186, 186);
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 16px;
}

.modalsecurity .input_content {
    position: relative;

    input {
        left: 0px;
        top: 0px;

        background: #ffffff;
        border: 1.5px solid #eaeaea;
        border-radius: 10px;
    }

    img {
        position: absolute;
        right: 12px;
        top: 27.5%;
    }
}

.modalsecurity {
    .feilddd {
        position: relative;
    }

    .xvshvsx {
        position: absolute;
        right: 16px;
        top: 29px;
    }

    .dghvgdv {
        color: #FF0083 !important;
        padding-bottom: 20px;
    }

    .dcdcdc {
        width: 70%;
    }

    .modal-content {
        text-align: center;
        padding: 10px;
        background: #FFFFFF;
        border-radius: 15px;

        .modal-body {
            .main-headersdsd {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .shvdshvd {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }

                .modal-title {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 110%;
                }

                button.close {
                    background-color: transparent;
                    -webkit-appearance: none;
                    position: absolute;
                    border: 1.5px solid #C8C8C8;
                    border-radius: 40px;
                    // padding: 8px;
                    cursor: pointer;
                    left: 86.33%;
                    top: -23.67%;
                    width: 40px;
                    height: 40px;
                    padding: inherit;
                }

                .bdjbsdjc {
                    // margin: 40px;
                }

                .sgdvsgdvgsvd {
                    align-items: center;
                    /* padding: 18px 40px; */
                    gap: 10px;
                    width: 100%;
                    height: 54px;
                    background: #FF0083;
                    border-radius: 10px;
                    margin: auto;
                    border: none;
                   font-weight: 600;
                    font-style: normal;
                    // font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    color: #FFFFFF;

                    &:hover {
                        color: #FF0083;
                        border-radius: 4px;
                        border: 2px solid;
                        background-color: #fff;
                        border-radius: 10px;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                button.btn.btn-common.btn-lg.btn-block {
                    align-items: center;
                    /* padding: 18px 40px; */
                    gap: 10px;
                    height: 52px;
                    background: #FF0083;
                    border-radius: 10px;
                    margin: auto;
                   font-weight: 600;
                    font-style: normal;
                    // font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    color: #FFFFFF;

                    &:hover {
                        color: #FF0083;
                        border-radius: 4px;
                        border: 2px solid;
                        background-color: #fff;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}

.connectwalletmodal {

    .modal-dialog {
        .modal-content {
            background: #000;
            backdrop-filter: blur(32px);
            border: 1px solid;
            border-radius: 15px;
        }

        .modal-body {
            .main-heading {
                position: relative;
                margin-top: 40px;
                padding: 40px 40;

                h3 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 30px;
                    color: #fff;
                    text-align: center;
                }

                .crossimg {
                    position: absolute;
                    top: -9px;
                    right: 0px;
                }

            }



            .clear {
                text-align: center;

                button {
                    background: #000;
                    border-radius: 15px;
                    padding-top: 24px;

                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 100%;
                    text-align: center;
                    color: #FF0083;
                    border: none;
                    cursor: pointer;
                    // width: 449px;
                    // border-top: 1px solid #EAEAEA;
                    width: 100%;
                    margin-bottom: 15px;
                    border-radius: 0px;
                }
            }

            .Submitproposal {
                .iconimages {
                    display: flex;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    justify-content: center;
                    align-items: center;

                    .leftimage {
                        background: #000;
                        border-radius: 15px;
                        //    width: 215px;
                        //    height: 215px;
                        width: 50%;
                        text-align: center;

                        //  padding: 44px 61px;
                        img {
                            margin-top: 30px;
                        }

                        &:hover {
                         border: 1px solid;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 25px;
                            line-height: 30px;

                            color: #fff;
                            padding-top: 5px;
                            padding-bottom: 44px;


                        }
                    }


                }

            }


        }
    }
}

@media (max-width: 6000px) {
    .sdvgdvsdhvd {
        display: none;
    }
}

@media (max-width: 2560px) {
    .sdvgdvsdhvd {
        display: none;
    }
}

@media (max-width: 1500px) {
    // .main-navbar .navbar-nav .nav-link{
    //     margin-right: 12px;
    // }
}

@media (max-width: 1300px) {
    .main-navbar .navbar-nav .nav-link {
        margin-right: 23px;
    }

    .main-navbar-mobile .navbar-nav .nav-link {
        margin-right: 23px;
    }

    .main-navbar .navbar-nav .nav-links {
        margin-right: 23px;
    }

    .main-navbar-mobile .navbar-nav .nav-links {
        margin-right: 23px;
    }
}

@media (max-width: 1199px) {
    .navbar .pinknavbtn {
        margin-right: 10px;
        // margin-bottom: 10px;
    }
    .gcsgcdgsdvcgc {
        width: auto;
    }

    .main-navbar .navbar-nav li a.active {
        margin-right: 0px !important;
    }

    .main-navbar-mobile .navbar-nav .nav-link.active {
        margin-right: 0px !important;
    }

    .navbar-collapse {
        box-shadow: 2px 7px 13px rgba(0, 0, 0, 0.09) !important;
    }

    .main-navbar-mobile .navbar-nav .nav-link:hover {
        border: none !important;
    }

    .navbar-collapse {
        background-color: #fAFAFA;
        border-radius: 18px;
        padding-bottom: 30px;
    }

    .main-navbar {
        height: unset;
    }

    .main-navbar .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .navbar-nav {
        margin-top: 30px;
    }

    .main-navbar-mobile .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar-mobile .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
        padding-bottom: 15px !important;
    }

    .sdvgdvsdhvd {
        display: block;
    }

    .navbar .ahsbahsba {
        display: none;
    }

    .button-hedaer {
        margin-left: 0px;
        margin-top: 20px;
    }

    .shdvs {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .main-navbar .sbvsx {
        display: flex;
        margin: 0 auto;
    }

    .main-navbar .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar .navbar-nav .nav-item.active .nav-link::before {
        content: "";
        bottom: 0px;
        right: 0;
        left: 0;
        position: absolute !important;
        width: 14% !important;
        margin: 0 auto;
        height: 3px !important;
    }

    .main-navbar {
        .form-inline {
            justify-content: center;
        }
    }
    .show-item{
        top: 90px;
        right: 154px;
    }
}

.main-navbar-mobile {
    .collapse.show {
        // display: none!important;
    }
}

@media (max-width: 991px) {
    .navbar-nav {
        margin-top: 30px;
    }

    .main-navbar-mobile .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar-mobile .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar {
        .formm-mm {
            .input-bbb {
                margin-left: 0px;
            }
        }

        .form-inline {
            justify-content: center;
        }
    }

    .main-navbar .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
        margin: 0 !important;
        padding-bottom: 15px !important;
    }

    .main-navbar .navbar-nav .nav-item.active .nav-link::before {
        content: "";
        bottom: 0px;
        right: 0;
        left: 0;
        position: absolute !important;
        width: 14% !important;
        margin: 0 auto;
        height: 3px !important;
    }

    .main-navbar .navbar-nav .dropdown .dropdown-menu {
        position: unset;
    }
    .show-item{
        top: 90px;
        right: 145px;
    }
}

@media (max-width: 600px) {
    .main-navbar{
        top: 80px;
        width: 100%;
    }
    .main-navbar-mobile .navbar {
        padding: 15px 0px 15px 0px;
    }

    .main-navbar-mobile {
        height: 82px;
    }

    .connectwalletmodal .modal-dialog .modal-body .Submitproposal .iconimages .leftimage p {
        font-size: 16px;
    }

    .main-navbar .navbar {
        padding: 20px 0px 20px 0px;
    }

    .connectwalletmodal .modal-dialog .modal-body .clear button {
        white-space: nowrap;
        font-size: 14px;
        text-align: center;
        justify-content: center;
    }

    .modal-dialog .modal-body .main-heading .crossimg {
        right: -11px !important;
    }

    .hbdsjbd {
        width: 167px;
    }

    .modal-body .main_text .bhvchvh {
        font-size: 43px !important;
    }
    .navbar .pinknavbtn{
        margin: 0 !important;
    }
    .main-navbar .navbar{
        justify-content: center !important;
    }
    .navbar .shdvs{
        // width: 100%;
        // justify-content: space-between;
        display: none;
    }
    .navbar .shdvs a{
        width: 48%;
    }
    .navbar .pinknavbtn{
        white-space: nowrap;
    }
    .navbar .btn-common{
        width: 100% !important;
        margin: 0 auto ;
        text-align: center;
    }
    .navbar .ajnbdsajdbnsj{
        width: 48%;
    }
    .navbar .pinknavbtn{
        width: 100% !important;
    }
}

.buttonsss {
    background-color: transparent;
    border: none;
}

@media (max-width: 400px) {
    .navbar-brand {
        img {
            width: auto;
        }
    }
}

@media (max-width: 400px) {
    .modal-body .main_text .bhvchvh {
        font-size: 30px !important;
    }
}

@media (max-width: 350px) {
    .navbar-brand {
        img {
            width: 150px;
        }
    }
}

.sbjdfbsj {
    display: none;
}

.ahsbahsba img{
    width: 60px;
    height: 60px;
}

@media (max-width:1199px){
    .main-navbar{
        max-width: 300px;
        left: unset !important;
        right: 135px;
        top: 0;
    }
    .main-navbar-mobile{
        right: 135px;
    }
}

@media (max-width:600px){
    .main-navbar{
       position: absolute;
       left: 0 !important;
       top: 80px !important;
       right: unset !important;
       max-width: 100% !important;
       z-index: 100;
    }
    .main-navbar-mobile{
      display: none;
    }
}