.Editprofile {
    // padding-top: 160px;
    padding-bottom: 70px;

    .rightmain {
        h6 {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
      
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 19.2px */
            margin-bottom: 6px;
        }

        .maininner {
            display: flex;
            align-items: center;
            padding-bottom: 50px;

            .avatarimg {
                width: 130px;
                height: 130px;
                border-radius: 50%
            }

            .innered {
                margin-left: 27px;

                p {
                    color: #8A8C90;
font-feature-settings: 'liga' off, 'clig' off;

font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 14.4px */
                    margin-bottom: 18px;
                    width: 200px;
                }

                .inning {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    .upload-btn {
                        padding: 10px 14px;
                        color: #FFF;
                        font-feature-settings: 'liga' off, 'clig' off;
                        width: 161px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        text-align: center;
                        line-height: 120%; /* 16.8px */
                        border-radius: 100px;
border: 1px solid #DD0083;
                        cursor: pointer;
                        margin-bottom: 0px;
                    }

                    .outing {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 8px;
                        background: #FFFFFF;
                        border: 1px solid rgba(255, 40, 40, 0.1);
                        border-radius: 90px;
                        margin-left: 15px;

                        .trashimg {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }

    .endbtn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 40px;
        background: #FF0083;
        border-radius: 100px;
        width: 100%;
        border: none;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
        margin-top: 15px;

        &:hover {
            box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
        }
    }

    .mainheadings {
        margin-bottom: 50px;
        // margin-top: 100px;

        .head {
            color: var(--White, #FFF);
            
            
            /* BIG ACCENT */
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 60px */
        }

        .para {
            color: #FFF;
           padding-top: 12px;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 22.5px */
            text-transform: uppercase;
        }
    }

    .maininput {
        position: relative;

        .copy {
            position: absolute;
            right: 14px;
            top: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #FF0083;
            text-decoration: none;
            border: none;
            background-color: transparent;
        }

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            color: #fff;
            margin-bottom: 12px;

            .discordimg {
                width: 24px;
                height: 24px;
                margin-left: 10px;
            }
        }

        .innerinput {
            padding: 10px 12px 10px 24px;
            border-radius: 39px;
            border: 1px solid var(--Accent, #FF0083);
            background-color: unset;
            width: 100%;
            margin-bottom: 20px;
            color: var(--White, #FFF) !important;
            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                color: #B3B3B3;
                width: 80%;
            }
        }

        // .innerinput {
        //     width: 100%;
        //     background: transparent;
        //     border: 1px solid #E2E2E2;
        //     border-radius: 5px;
        //     margin-bottom: 25px;
        //     padding: 14px;
        //     color: #fff;

        //     &::placeholder {
        //         font-style: normal;
        //         font-weight: 400;
        //         font-size: 16px;
        //         line-height: 140%;
        //         color: #D3D3D3;
        //     }
        // }
    }
}

@media (max-width:1200px){
    .Editprofile .direction{
        display: flex;
        flex-direction: column-reverse;
    }
.Editprofile{
    padding: 20px;
}
    .Editprofile .direction .padd{
        padding: 0px;
        margin-bottom: 40px;
    }
    .uppper_part{
        width: 100% !important;
        padding: 50px 15px !important;
        
    }
    .uppper_inputs{
        flex-direction: column;
    }
    .innerinput{
        font-size: 10px !important;
    }
    .copy{
        right: 8px !important;
    top: 5px !important;
    }
}
@media (max-width:400px){
    .maininner{
        flex-direction: column !important;
        gap: 20px !important;
    }
}
.myyydirection{
    display: flex;
    flex-direction: column;
}
.linesshades{
    position: absolute;
    top: 0px;
    width: 100%;
}
.linesshades2{
    position: absolute;
    top: 100px;
    right: 290px;
}
.linesshades3{
    top: 314px;
    position: absolute;
        z-index: -1;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
}
.uppper_part{
    border-radius: 30px;
    padding: 58px 60px;
width: 1200px;
margin: 0 auto;
position: relative;
background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);


-webkit-backdrop-filter: blur(35.1px);
backdrop-filter: blur(35.1px);

&:after {
  content: '';
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  background-color: #ffffff;
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  border: 1.7px solid transparent;
  background: -o-linear-gradient(#111111 0 0) padding-box, -o-linear-gradient(264.93deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
  background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
  z-index: -1;
}
}
label{
    margin-bottom: 10px;
    color: #fff;
}
.uppper_inputs{
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
}
.firstttt{
    width: 100%;
}