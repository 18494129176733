.main-footer {
    border-top: 1px solid #282828;
    background: var(--bg-100, #111);
    z-index: 99;
    position: relative;

    .footertop {
        padding: 48px 0px 73px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .topleft {
            .topleftimg {
                margin-bottom: 19px;
            }

            .topleftpara {
                color: var(--White, var(--White, #FFF));
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }

        .topright {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 14px;

            .sociallink {
                display: flex;
                width: 32px;
                height: 32px;
                padding: 7.529px;
                justify-content: center;
                align-items: center;
                border-radius: 170px;
                background: rgba(40, 40, 40, 0.20);
                backdrop-filter: blur(10px);
                text-decoration: none !important;
            }
        }
    }

    .footermid {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 73px;

        .footermidright {
            .midinner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .midhead {
                    color: var(--White, var(--White, #FFF));
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    margin-bottom: 26px;
                }

                .midpara {
                    color: var(--White, #FFF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 12px;
                }
            }
        }

        .footermidleft {
            display: flex;
            align-items: flex-start;
            max-width: 870px;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;

            .midinner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .midhead {
                    color: var(--White, var(--White, #FFF));
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    margin-bottom: 26px;
                }

                .midpara {
                    color: var(--White, #FFF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 12px;
                }
            }
        }
    }

    .footerlast {
        color: var(--Accent, #FF0083);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        display: flex;
        padding: 24px 0px;
        justify-content: center;
        align-items: center;
        position: relative;

        .footerlastline {
            position: absolute;
            height: 1px;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            background: var(--tomi-footer-gradient, linear-gradient(90deg, rgba(255, 0, 131, 0.00) 5%, #FF0083 49.5%, rgba(255, 0, 131, 0.00) 95%));
        }
    }
}

@media(max-width:992px) {
    .main-footer .footermid {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px 20px;
    }

    .main-footer .footertop {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;

        .topleft {
            justify-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }
    }
    .main-footer .footermid .footermidleft .midinner{
        width: 50%;
    }
    .main-footer .footermid .footermidleft{
        gap: 30px 0px;
    }
    .sdhgavschjBcasjkbf h4{
        font-size: 18px !important;
    }
}