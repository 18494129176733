.banner-main {
  .pinkbtn {
    color: var(--White, #fff);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
    border-radius: 100px;
    border: 1px solid var(--Accent, #ff0083);
    background: var(--Accent, #ff0083);
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
  }

  .pinkbg {
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .topTicket {
    padding: 16px 20px;
    background: #ff0083;
    border-radius: 5px;
    width: 221px;
    justify-content: space-between;

    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  .governancebox {
    padding-top: 33px;

    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;

      color: #2e3433;
    }

    h3 {
      font-style: normal;
      font-weight: 900;
      font-size: 45px;
      line-height: 110%;
      text-align: center;
      color: #fff;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    span {
    }

    .futttture {
      color: #ff0083 !important;
      font-size: 55px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
    }

    p {
      color: #a6a6a6;
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 27px */
      margin-top: 15px;
    }
  }

  .treasuryBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    margin-top: 50px;
    flex-wrap: wrap;
    border-radius: 10px;
    position: relative;
    background: linear-gradient(
        103deg,
        rgba(255, 160, 198, 0.15) 0%,
        rgba(126, 126, 126, 0.01) 110.97%
      ),
      rgba(29, 29, 29, 0.2);

    -webkit-backdrop-filter: blur(35.1px);
    backdrop-filter: blur(35.1px);

    &:after {
      content: "";
      position: absolute;
      inset: 0px;
      border-radius: inherit;
      background-color: #ffffff;
      -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box,
        -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      border: 1.7px solid transparent;
      background: -o-linear-gradient(#111111 0 0) padding-box,
        -o-linear-gradient(264.93deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
      background: linear-gradient(#111111 0 0) padding-box,
        linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%)
          border-box;
      z-index: -1;
    }

    .treasuryfirst {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      border-right: 1px solid #4b4b4b;
      flex-grow: 1;
      justify-content: center;

      &:last-child {
        border-right: none !important;
      }

      // span:nth-child(2) {
      //     border-left: 1px solid #b3a8bc;
      // }

      span {
        padding: 44px;
        height: 167px;

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #fff;
        }

        .smallheading {
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 100%;
          color: #a6a6a6;
          margin-bottom: 18px;
        }

        h4 {
          font-style: normal;
          font-weight: 700;
          font-size: 35px;
          color: #fff;
        }

        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 38px;
          line-height: 100%;
          color: #fff;
        }
      }
    }

    .treasuryP {
      padding: 15px 20px;
      border-left: 1px solid #b3a8bc;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #767676;
      }
    }
  }
  .ptagssspan {
    color: #959595;
    padding-right: 17px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
  }
  .porposalDiv {
    .timeDiv {
      border-radius: 5px;
      padding: 19px 20px;
      margin: 20px 0px;
      border-radius: 140px;

      position: relative;
      background: linear-gradient(
          103deg,
          rgba(255, 160, 198, 0.15) 0%,
          rgba(126, 126, 126, 0.01) 110.97%
        ),
        rgba(29, 29, 29, 0.2);

      -webkit-backdrop-filter: blur(35.1px);
      backdrop-filter: blur(35.1px);

      &:after {
        content: "";
        position: absolute;
        inset: 0px;
        border-radius: inherit;
        background-color: #ffffff;
        -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff))
            content-box,
          -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        border: 1.7px solid transparent;
        background: -o-linear-gradient(#111111 0 0) padding-box,
          -o-linear-gradient(264.93deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
        background: linear-gradient(#111111 0 0) padding-box,
          linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%)
            border-box;
        z-index: -1;
      }

      .dullbutton {
        display: flex;
        padding: 0px 40px;
        height: 39px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        color: #b6b6b6;
        border-radius: 225px;
        background: #222;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-right: 15px;
        border: none;
      }

      .noDiv {
        display: flex;
        align-items: center;

        p:nth-child(1) {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #959595;
          margin-right: 17px;
        }

        p:nth-child(2) {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #fff;
        }
      }
      .innnerptag {
        color: #fff;

        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 20px */
      }

      .greyButton {
        padding: 12px 30px;
        display: flex;
        align-items: center;
        gap: 5px;
        border-radius: 225px;
        background: #222;
        outline: none;

        .card-heading-4 {
          color: #b6b6b6;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 16px */
          /* 16px */
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #b6b6b6;
        }

        h5 {
        }
      }

      .greyButtons1 {
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 9px 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
        outline: none;
        height: 100%;
        border: none;
        box-shadow: none;
      }
    }

    .porp {
      font-style: normal;
      font-weight: 700;
      font-size: 45px;
      text-transform: capitalize;
      margin-bottom: 20px;
      color: #fff;
    }

    .porps {
      margin-right: 127px;
    }

    .walletDiv {
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-transform: uppercase;
        color: #767676;
        margin-right: 40px;
      }

      .btn-porposal {
        padding: 16px 20px;
        border: 1px solid transparent;

        &:hover {
          background-color: transparent;
          border: 1px solid #ff0083;
          color: #ff0083;
          border-radius: 5px;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          height: 100%;
          padding: 16px 20px;
          text-decoration: none !important;
        }
      }
    }
  }

  .brdr {
    border: 1px solid#4E4E4E;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .comments {
    .comments-heading {
      margin-bottom: 20px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        color: #fff;
      }
    }

    .commentstextarea {
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #fff;
        margin-bottom: 10px;
      }

      .commentstext {
        padding: 20px;
        width: 100%;
        background: transparent;
        border: 1px solid #ffe9f4;
        border-radius: 5px;
        height: 152px;
        outline: none;
        color: #fff !important;
        border-radius: 12px;
        border: 1px solid #ff0083;

        &::placeholder {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
          color: #cacaca;
        }
      }
    }

    .btnpost {
      margin-top: 30px;

      button {
        background: #ff0083;
        box-shadow: 0px 28px 30px -10px rgba(255, 0, 131, 0.15);
        border-radius: 50px !important;
        align-items: center;
        padding: 20px 40px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff;
      }

      .btnnnna {
        background: #cbb2bf;
        box-shadow: 0px 28px 30px -10px rgba(255, 0, 131, 0.15);
        border-radius: 5px;
        align-items: center;
        padding: 20px 40px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff;
      }
    }
  }

  .comment {
    .headingsss {
      margin-bottom: 40px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        color: #fff;
      }
    }

    .parent {
      display: flex;
      align-items: center;

      .left-content {
        margin-right: 13.2px;

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .right-content {
        .doyle {
          .doylehead {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 130%;
            color: #000000;
          }

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: #b5b5b5;
            margin-top: 2px;
          }
        }
      }
    }

    .mainpara-comment {
      .para {
        margin-top: 26.2px;

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 130%;
          color: #727272;
        }
      }
    }
  }
}

.nyyyyyyyyyyyyyyyy {
  display: flex;
  align-items: center;
  gap: 9px;
}

@media (max-width: 827px) and (min-width: 768px) {
  .banner-main .porposalDiv .timeDiv .greyButton {
    // background: #f1f1f1;
    // border-radius: 5px;
    // margin-right: 3.5px;
    // display: flex;
    // align-items: center;
    // padding: 9px 7px;
    // outline: none;
  }

  .banner-main .countSection .ThresholdDiv {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px !important;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #989898;
    font-style: normal;
  }
}

@media (max-width: 601px) {
  .banner-main .porposalDiv .walletDiv p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    color: #767676;
    margin-right: 0px;
  }

  .banner-main .porposalDiv .timeDiv {
    border-radius: 10px !important;
  }

  .banner-main .treasuryBox span:nth-child(4) {
    flex: 0.2 1;
    text-align: center;
    height: 100%;
    width: 1px;
    padding: 20px;
    display: none;
  }

  .banner-main .governancebox {
    padding-top: 32px;
  }

  .banner-main .porposalDiv .timeDiv .greyButtons {
    background: #f1f1f1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 9px 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    margin-top: 20px;
    margin-right: 29px;
  }

  .banner-main .treasuryBox .treasuryfirst span h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
  }

  .banner-main .porposalDiv .timeDiv .noDiv {
    display: flex;

    align-items: flex-start;
  }

  .banner-main .treasuryBox .treasuryfirst span:nth-child(2) {
    border-left: none;
  }

  .banner-main .treasuryBox .treasuryP {
    border-left: none;
  }

  //fontsizes
  .banner-main .governancebox h3 {
    font-size: 18px;
  }

  .banner-main .governancebox p {
    font-size: 15px;
    margin-bottom: 31px;
  }

  .banner-main .governancebox h3 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .banner-main .treasuryBox .treasuryfirst span h4 {
    font-size: 32px;
  }

  .banner-main .countSection .forAganist .text-dangerr {
    font-size: 18px !important;
  }

  .banner-main .porposalDiv .porp {
    font-size: 35px;
  }

  .banner-main .porposalDiv .porps {
    margin-right: 0px;
  }

  .banner-main {
    padding-top: 0px;
  }

  .banner-main .bg-primary {
    width: 85px !important;
    height: 30px;
  }

  .navbar-brand img {
    max-width: 108px;
  }

  .main-submit {
    padding-top: 130px !important;
  }

  .banner-main .porposalDiv .timeDiv {
    padding: 11px 3px;
  }

  .banner-main .comments .comments-heading h2 {
    white-space: nowrap;
    font-size: 21px !important;
  }

  .banner-main .comments .commentstextarea p {
    font-size: 18px;
  }

  .banner-main .comment .headingsss h2 {
    font-size: 21px !important;
  }

  .banner-main .comment .parent .right-content .doyle .doylehead {
    font-size: 17px;
  }

  .banner-main .comment .parent .right-content .doyle p {
    font-size: 14px;
  }

  .banner-main .comment .mainpara-comment .para p {
    font-size: 17px !important;
  }

  .buy-pioneer-modal11 .modal-dialog .modal-content {
    min-width: 100% !important;
  }

  .connect-wallet-btn {
    display: block !important;
    background: #ff0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    border-radius: 100px !important;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    padding: 16px 18px !important;
    margin: 0 auto;
  }

  .connectwallet-mobile {
    .modal-header {
      border: none;
      padding: 20px;
      justify-content: center;

      .modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #171515;
      }
    }

    .modal-content {
      background: #ffffff;
      border-radius: 15px;
    }

    .modal-body {
      padding: 20px;
    }

    .btn-close {
      background: url("../../Assets/close-icon.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 20px;
      right: 25px;
      border: none;
      opacity: 1;
    }

    .connect-btn {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #f2f2ff;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;

      .inner-content {
        display: flex;
        justify-content: center;
        align-items: center;

        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #171515;
        }
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #959595;
        margin-top: 25px;
      }
    }
  }
}

.submit-proposal-modal {
  background: rgba(32, 30, 30, 0.65);
  backdrop-filter: blur(2px);

  .modal-header {
    padding: 0px;
    border: none;
    align-items: center;

    .modal-title {
      color: #fff;
      margin: 0;
      font-size: 39.942px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      /* 39.942px */
      text-transform: capitalize;
      text-align: center;
      width: 100%;
      margin-bottom: 52px;
    }

    .btn-close {
      background: url("../../Assets/add-circle.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: none;
      padding: 15px;
    }
  }

  .modal-content {
    border-radius: 30px;
    border: 1px solid #ff0083;
    background: linear-gradient(
        103deg,
        rgba(255, 160, 198, 0.06) 0%,
        rgba(126, 126, 126, 0) 110.97%
      ),
      rgba(29, 29, 29, 0.05);
    backdrop-filter: blur(92px);
    padding: 30px;
  }

  .modal-dialog {
    max-width: 695px;
  }

  .modal-body {
    padding: 0px;

    button {
      display: block;
      width: 100%;
      padding: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #292d32;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      border-radius: 130px;
      border: 1px solid #ff0083;
      background-color: transparent;

      &:hover {
        background: #ff0083;
        color: #fff;
        //   .change-my-color path{
        //       fill: #fff;
        //   }
        //   .change-my-color1 path{
        //       stroke: #fff;
        //   }
      }
    }

    .spec path {
      fill: #fff;
    }

    .change-my-color1 path {
      fill: #fff;
    }
  }
}

.buy-pioneer-modal {
  background: rgba(32, 30, 30, 0.65);
  backdrop-filter: blur(2px);

  .close {
    opacity: 1;
  }

  .modal-dialog {
    max-width: 464px;
  }

  .modal-header {
    padding: 20px 30px;
  }

  .modal-body {
    padding: 30px 40px;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ff0083;
    margin-bottom: 17px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #767676;
    margin-bottom: 30px;
  }

  .buy-btn {
    background: #ffffff;
    border: 1.5px solid #ff0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff0083;
    padding: 14px 30px;
  }
}

.buy-pioneer-modal11 {
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      min-width: 571px;
      background: #ffffff;
      // border: 1.5px solid #FF0083;
      border-radius: 5px;

      .modal-header {
        padding: 0%;
        border-bottom: none;
        padding-top: 18px;
        position: relative;

        .modal-title {
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 130%;
          text-align: center;
          color: #292929;
          width: 100%;
        }

        .close {
          position: absolute;
          top: 19px;
          right: 22px;
        }
      }

      .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 58px;
        padding-right: 34px;
        padding-left: 34px;
        padding-bottom: 30px;

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 130%;
          text-align: center;
          color: #292929;
          margin-bottom: 20px;

          span {
            color: #ff0083;
          }
        }

        h5 {
          font-style: normal;
          font-weight: 700;
          font-size: 23px;
          line-height: 130%;
          text-align: center;
          color: #ff0083;
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .buy-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 70px;
          background: #ff0083;
          border-radius: 5px;
          font-weight: 600;
          font-style: normal;
          font-size: 16px;
          line-height: 100%;
          color: #ffffff;
          width: 100%;
          margin-top: 22px;
        }
      }
    }
  }
}

.show-item {
  border: 1px solid #ff0083;
  background: linear-gradient(
      103deg,
      rgba(255, 160, 198, 0.15) 0%,
      rgba(126, 126, 126, 0.01) 110.97%
    ),
    rgba(29, 29, 29, 0.2);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  position: absolute;
  top: 145px;
  right: 24px;
  z-index: 999;
  border-radius: 32px;
  width: 100%;
  max-width: 363.33px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .innerset {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .innersetleft {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      .innersetimg {
        width: 43px;
        height: 43px;
        border-radius: 150px;
        object-fit: cover;
        object-position: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .innerimg {
          width: 100%;
          height: 100%;
          border-radius: 150px;
          object-fit: cover;
          object-position: center;
        }
      }

      .lefttexts {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;

        .lefthead {
          color: #ffffff;
          font-size: 18px;
          font-weight: 600;
          line-height: 18px;
        }

        .leftpara {
          color: #a6a6a6;
          font-size: 12px;
          font-weight: 400;
          margin: 0 !important;
          line-height: 12px;
        }
      }
    }

    .innersetright {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      gap: 5px;

      .righthead {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
      }

      .rightpara {
        color: #a6a6a6;
        font-size: 12px;
        font-weight: 400;
        margin: 0 !important;
        line-height: 12px;
      }
    }
  }

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;

    span {
      font-weight: 700;
    }
  }
}

@media (max-width: 600px) {
  .mobile-show-pioneer {
    display: flex !important
;
    position: unset;
    /* margin-bottom: 30px; */
    /* margin-top: 30px; */
    width: 301px;
    justify-content: center;
    /* align-items: baseline; */
    margin: 0 auto;
    margin-top: 30px;
}

  .desktop-show-pioneer {
    display: none;
  }

  .show-item {
    max-width: 100%;
  }

  .navbar .pinknavbtn .btnimage {
    width: 22px !important;
    height: 22px !important;
  }

  .navbar .pinknavbtn {
    font-size: 14px !important;
    height: 54px !important;
  }

  .navbar .btn-common {
    font-size: 14px;
    height: 54px !important;
  }

  .banner-main .treasuryBox .treasuryfirst span h5 {
    font-size: 18px;
  }

  .banner-main .treasuryBox .treasuryfirst span {
    padding: 24px;
  }

  .banner-main .treasuryBox .treasuryfirst {
    flex: 1 0 50%;
  }

  .banner-main .treasuryBox .treasuryfirst span .smallheading {
    font-size: 12px;
  }

  .banner-main .porposalDiv .timeDiv .greyButton {
    margin: 0 auto;

    h5 {
      font-size: 14px;
    }
  }

  .banner-main .porposalDiv .timeDiv .greyButtons {
    margin: 0 auto;
  }

  .banner-main .porposalDiv .timeDiv .noDiv {
    justify-content: center;
  }

  .banner-main .porposalDiv .timeDiv .noDiv p:nth-child(2) {
    text-align: start;
    white-space: nowrap;
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ytsfvxuvsxuf {
    justify-content: center;
    gap: 15px;
  }

  .banner-main .treasuryBox {
    align-items: flex-start;
  }

  .banner-main .top-borderrrr {
    border-top: 1px solid #4b4b4b;
  }

  .logo-tomidao-mobile {
    display: block !important;
    max-width: 164px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.estimated-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #fff;
}

@media (max-width: 600px) {
  .estimated-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #2e3433;
  }

  .ptagggggs {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

a {
  color: rgb(73, 73, 73);
  text-decoration: underline;
}

.twice-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;

  .artdao-btn {
    background: #fafbfc;
    border: 1px solid #ff0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    padding: 16px 27px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ff0083;
    transition: 0.3s linear;

    &:hover {
      background: #ff0083;
      color: #ffffff !important;
    }
  }

  .forum-btn {
    background: #ffffff;
    border: 1px solid #ff0083;
    color: #ff0083 !important;
    border-radius: 5px;
    padding: 16px 27px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    transition: 0.3s linear;
    margin-left: 15px;
    transition: 0.3s linear;

    &:hover {
      background: #ff0083;
      border: 1px solid transparent;
      color: #ffffff !important;
      box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    }
  }
}

.innnerptag {
  color: #fff !important;
}

.Htag {
  font-style: normal;
  font-weight: 900 !important;
  font-size: 45px !important;
  line-height: 110%;
  text-align: center !important;
  color: #fff;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.rdw-editor-toolbar {
  border: 1px solid #ff0083 !important;
  /* border: 1px solid var(--Accent, #ff0083) !important; */
  /* background: #111 !important; */
  background: unset !important;
  border-radius: 20px 20px 0px 0px !important;
  padding: 10px 18px !important;
  /* border-bottom: unset; */
  margin-bottom: -13px !important;
}
.rdw-editor-main {
  border: 1px solid #ff0083 !important;
  /* border: 1px solid var(--Accent, #ff0083) !important; */
  background: transparent !important;
  border-radius: 0px 0px 20px 20px !important;
  border-top: unset !important;
}
.public-DraftStyleDefault-ltr {
  color: #fff;
}
.rdw-editor-toolbar {
  // display: none !important;
}
.ytsfvxuvsxuf {
  gap: 20px;
}
.btnimage {
  width: 44px;
  height: 44px;
  border-radius: 40px;
  border: 1px solid var(--Accent, #ff0083);
}
.taggeeeumh {
  width: 156px;
  height: 156px;
}

.mydatacollectin {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #fff;
  margin-bottom: 10px;
}
.qussdiv {
}
.rdw-option-wrapper {
  border: unset !important;
  background: #FF0083 !important;
}
.rdw-option-wrapper:hover {
  box-shadow: unset !important;
}

.rdw-dropdown-wrapper {
  height: 23px !important;
  background: #FF0083 !important;
  border: unset !important;
}
.rdw-dropdown-wrapper:hover {
  box-shadow:unset !important;
  color: unset !important;
}
.rdw-editor-toolbar {
  span{
    color: #000000 !important;
  }
  ::-webkit-scrollbar{
    display: none;
  }
}
.rdw-dropdown-optionwrapper {
  background-color: #000000 !important;
}
.rdw-dropdown-optionwrapper {
  color: #ff0083 !important;
}
.rdw-dropdown-optionwrapper {
  color: #ff0083 !important;
}
.rdw-dropdown-optionwrapper {
  color: #ff0083 !important;
  border: unset !important;
}
.rdw-dropdownoption-active {
  background-color:#ff0083 !important ;
  color: #000000 !important;
}
.rdw-dropdownoption-highlighted {
  background-color:#ff0083 !important ;
  color: #000000 !important;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow:unset !important;
  background-color: #FFFFFF;
}

.categorymodal{
  .modal-dialog {
    .modal-content {
      border-radius: 15px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(120, 120, 120, 0.03) 100%);
      border: 1px solid #FFFFFF1A;
      backdrop-filter: blur(160px);
      .modal-header {
        border: unset;
        // cursor: pointer;
      }
      .modal-body {
        padding: 0px;
        .modal__body {
          display: flex;
          flex-direction: column;
          align-items: center;
          h5 {
            color: #FFF;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 24px */
            padding-top: 30px;
          }
          p {
            color: #BCBCBC;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
          }
          h2 {
            color: #FF0083;
            text-align: center;
            padding-bottom: 30px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          button {
            color: #FFF;
            border: unset;
            font-size: 16px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: 218px;
            padding: 15px 0px;
            border-radius: 120px;
            background: #FF0083;
            margin-bottom: 54px;
          }
          // button:hover{
          //   color: #FFFFFF;
          //   background-color: var(--lightPrimary);
          //   border-color: var(--lightPrimary);
          // }
        }
      }
    }
  }
}
.modal-header .btn-close {
  background: url(../../Assets/cross.svg);
  background-repeat: no-repeat;
  box-shadow: unset;
  opacity: unset;
  height: 30px;
  width: 20px;
  cursor: pointer;
  margin-top: 2px;
}
.modal-backdrop.fade {
  opacity: 0 !important;
  background: rgb(0 0 0 / 50%) !important;
  // z-index: 9999999 !important;
}
.modal-open .modal {
  opacity: unset !important;
  background: rgb(0 0 0 / 50%) !important;
  backdrop-filter: unset !important;
}