.mainprivacypolicy {
    min-height: 100vh;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 30px;

    a{
        color: #FF0083 !important;
    }


    .privacypolicy {
        position: relative;

        .privacypolicyhead {
           color: #ffffff;
            font-size: 120px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            white-space: nowrap;
            position: absolute;
            top: 45px;
        }

        .topprivacypolicy {
            border-top: 1px solid #DEDEDE;
            border-bottom: 1px solid #DEDEDE;
            padding: 40px 0px;

            .marginbottomzero {
                margin-bottom: 0px !important;
            }

            .privacypolicytoppara {
               color: #ffffff;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                margin-bottom: 30px;
            }

            .privacypolicytophead {
                color: #FF0083;
                font-size: 27px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                margin-bottom: 20px;
                text-transform: uppercase;
            }

            ol {
                padding-left: 20px;
                margin-bottom: 0px;

                .privacypolicytopheadlist {
                   color: #ffffff;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%;
                }
            }
        }

        .termsflex {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
            margin-bottom: 21px;

            .termsnumber {
                color: #FF0083;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }

            .termspara {
               color: #ffffff;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
            }
        }

        .privacypolicymainhead {
            color: #FF0083;
            margin-top: 50px;
            margin-bottom: 30px;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
        }

        .marginbot {
            margin-bottom: 21px;
        }

        .privacypolicylowerlisthead {
           color: #ffffff;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin: 21px 0px;
        }

        ol {
            padding-left: 20px;
            margin-bottom: 0px;

            .privacypolicytopheadlist {
               color: #ffffff;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 160%;
            }
        }

        .privacypolicymainpara {
           color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;

            .pinklink {
                text-decoration: none;
                color: #FF0083;
            }
        }
    }
    .upper-head {
        h1 {
           color: #ffffff;
            font-size: 120px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }
}

@media (max-width:1350px){
    .mainprivacypolicy .upper-head h1{
        font-size: 110px;
    }
}

@media (max-width:1080px) {
    .upper-head h1{
        font-size: 32px !important;
        margin: 45px 0;
        text-align: start;
    }
    .mainprivacypolicy .privacypolicy .privacypolicyhead {
        position: unset;
        font-size: 32px !important;
        margin-bottom: 45px;
    }

    .mainprivacypolicy .privacypolicy {
        padding-top: 45px;
    }
}

@media (max-width:1080px) {
    .mainprivacypolicy .privacypolicy .topprivacypolicy .privacypolicytoppara {
        font-size: 16px;
    }

    .mainprivacypolicy .privacypolicy .topprivacypolicy .privacypolicytophead {
        font-size: 22px;
    }

    .mainprivacypolicy .privacypolicy .topprivacypolicy ol .privacypolicytopheadlist {
        font-size: 16px;
    }

    .mainprivacypolicy .privacypolicy .privacypolicymainhead {
        font-size: 26px;
    }

    .mainprivacypolicy .privacypolicy .privacypolicymainpara {
        font-size: 16px;
    }

    .mainprivacypolicy .privacypolicy .privacypolicylowerlisthead {
        font-size: 18px;
    }

    .mainprivacypolicy .privacypolicy ol .privacypolicytopheadlist {
        font-size: 16px;
    }
    .mainprivacypolicy .privacypolicy .termsflex .termsnumber{
        font-size: 16px;
    }
    .mainprivacypolicy .privacypolicy .termsflex .termspara{
        font-size: 16px;
    }
}


@media (max-width:390px){
    .mainprivacypolicy .upper-head h1{
        font-size: 26px !important;
    }
}