.banner-main {
  button {
    // background: #4965f0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 9px 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    outline: none;
    border-radius: 100px;
    height: 100%;
    border: none;
    box-shadow: none;
  }

  .bg-success {
    border-radius: 20px !important;
    background: #44ba73 !important;
    width: 105px !important;
    text-align: center !important;
    display: flex;
    justify-content: center;
  }

  .bg-primary {
    border-radius: 20px !important;
    background: #8c8d92 !important;
    text-align: center !important;
    display: flex;
    justify-content: center;
    width: 105px;
  }

  button.bg-success:focus,
  button.bg-success:hover {
    background: #44ba73 !important;
  }

  button.bg-primary:focus,
  button.bg-primary:hover {
    background: #8c8d92 !important;
  }

  button.bg-secondary:focus,
  button.bg-secondary:hover {
    background: #4965f0 !important;
  }

  button.bg-danger:focus,
  button.bg-danger:hover {
    background-color: #e40536 !important;
  }

  .bg-secondary {
    border-radius: 20px !important;
    background: #4965f0 !important;
    text-align: center !important;
    display: flex;
    justify-content: center;

    width: 105px !important;
  }

  .bg-danger {
    border-radius: 20px !important;
    background: #e40536 !important;
    width: 105px !important;
    text-align: center !important;
    display: flex;
    justify-content: center;
  }

  .bg-pink {
    background: #ff0083 !important;
  }

  .topBtnsDiv {
    .mainnnny {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .newwwwwbutton {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        position: relative;
        $border: 1.5px;
        color: #fff;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          margin: -$border;
          /* !importanté */
          border-radius: inherit;
          /* !importanté */
          background: var(--gradient, linear-gradient(180deg, #ff0083 0%, #171717 103.07%));
        }
      }
    }

    .mainheaddd {
      font-style: normal;
      font-weight: 900;
      font-size: 45px;
      line-height: 110%;
      text-align: start;
      color: #fff !important;
    }

    .textbtn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;

      h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 100%;
        text-align: center;
        color: #fff;
        margin-right: 20px;
      }
    }

    // p span {
    //     color: #ff0083 !important;
    //     font-weight: 400;
    //     font-size: 18px;
    // }

    .randomtext {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-direction: row;

      .texted {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        p {
          font-style: normal;
          font-weight: 900;
          font-size: 18px;
          line-height: 150%;
          text-transform: uppercase;
          color: #fff;

          span {
            font-style: normal;
            font-weight: 900;
            font-size: 18px;
            line-height: 150%;
            text-transform: uppercase;
            color: #ff0083 !important;

            a {
              color: #ff0083 !important;
              text-decoration: underline !important;
            }
          }
        }
      }

      .votesDiv {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 150%;
        text-transform: uppercase;
        color: #fff;

        p {
          font-style: normal;
          font-weight: 900;
          font-size: 18px;
          line-height: 150%;
          text-transform: uppercase;
          color: #fff;
          margin-top: 0px;
        }
      }
    }
  }

  .countSection {
    padding: 59px 0px;

    .forAganist {
      padding: 48px 90px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 12px;

      position: relative;
      background: linear-gradient(
          103.06deg,
          rgba(255, 160, 198, 0.06) 0%,
          rgba(126, 126, 126, 0.003) 110.97%
        ),
        rgba(29, 29, 29, 0.05);
      -webkit-backdrop-filter: blur(35.1px);
      backdrop-filter: blur(35.1px);

      &:after {
        content: "";
        position: absolute;
        inset: 0px;
        border-radius: inherit;
        background-color: #ffffff;
        -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff))
            content-box,
          -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        border: 1.7px solid transparent;
        background: -o-linear-gradient(#111111 0 0) padding-box,
          -o-linear-gradient(264.93deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
        background: linear-gradient(#111111 0 0) padding-box,
          linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%)
            border-box;
        z-index: -1;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        text-align: center;
        color: #00c643;
        text-transform: none;
      }

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        color: #fff;
      }

      .text-dangerr {
        color: #f21d1d !important;
        text-align: center;
        padding-top: 11px;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 22px */
      }
    }

    .ThresholdDiv {
      margin: 20px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 26px;
      border-radius: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #989898;
      font-style: normal;
      position: relative;
      background: linear-gradient(
          103deg,
          rgba(255, 160, 198, 0.15) 0%,
          rgba(126, 126, 126, 0.01) 110.97%
        ),
        rgba(29, 29, 29, 0.2);

      -webkit-backdrop-filter: blur(35.1px);
      backdrop-filter: blur(35.1px);

      &:after {
        content: "";
        position: absolute;
        inset: 0px;
        border-radius: inherit;
        background-color: #ffffff;
        -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff))
            content-box,
          -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        border: 1.7px solid transparent;
        background: -o-linear-gradient(#111111 0 0) padding-box,
          -o-linear-gradient(264.93deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
        background: linear-gradient(#111111 0 0) padding-box,
          linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%)
            border-box;
        z-index: -1;
      }

      .qutoa {
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        /* identical to box height, or 18px */

        text-align: center;

        color: #fff;
      }

      p {
        color: #989898;
        font-feature-settings: "liga" off, "clig" off;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
      }
    }
  }

  .descriptiondd {
    margin-bottom: 50px;
    padding-top: 0px;
  }

  .vote {
    .modal-dialog {
      max-width: 695px;

      .modal-content {
        border-radius: 30px;
        border: 1px solid #ff0083;
        background: linear-gradient(
            103deg,
            rgba(255, 160, 198, 0.06) 0%,
            rgba(126, 126, 126, 0) 110.97%
          ),
          rgba(29, 29, 29, 0.05);
        backdrop-filter: blur(92px);
        padding: 30px;
      }

      .modal-body {
        padding: 0px;

        .main-heading {
          h3 {
            color: #fff;
            text-align: center;
            width: 100%;
            font-size: 39.942px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: capitalize;
            margin-bottom: 38px;
          }
        }

        .confirmbtn {
          text-align: center;

          button {
            border-radius: 100px;
            border: 1px solid var(--Accent, #ff0083);
            background: var(--Accent, #ff0083);
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            color: var(--White, #fff);
            text-align: center;
            font-family: Mada;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: capitalize;
            max-width: 256px;
            width: 100%;
            margin: 38px auto 0;
          }
        }

        .vote-btn {
          .votefor {
            background: transparent;
            border: 1px solid #ff0083;
            border-radius: 130px;
            padding: 14px 100px;
            color: #fff;
            font-size: 20.92px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            width: 100%;
            display: block;

            &:hover {
              background: #ff0083;
              color: #ffffff;
            }
          }

          .votefor1 {
            border: 1px solid #ff0083;
            background: #ff0083;
            color: #ffffff;
            border-radius: 130px;
            padding: 14px 100px;
            color: #fff;
            font-size: 20.92px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            width: 100%;
            display: block;
          }
        }

        .voteagainst {
          background: transparent;
          border: 1px solid #ff0083;
          border-radius: 130px;
          padding: 14px 100px;
          color: #fff;
          font-size: 20.92px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;
          display: block;
          margin: 30px 0px;

          &:hover {
            background: #ff0083;
            color: #ffffff;
          }
        }

        .voteagainst1 {
          background: #ff0083;
          color: #ffffff;
          border: 1px solid #ff0083;
          border-radius: 130px;
          padding: 14px 100px;
          color: #fff;
          font-size: 20.92px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;
          display: block;
          margin: 30px 0px;
        }

        .voteabstain {
          background: transparent;
          border: 1px solid #ff0083;
          border-radius: 130px;
          padding: 14px 100px;
          color: #fff;
          font-size: 20.92px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;
          display: block;

          &:hover {
            background: #ff0083;
            color: #ffffff;
          }
        }

        .voteabstain1 {
          background: #ff0083;
          color: #ffffff;
          border: 1px solid #ff0083;
          border-radius: 130px;
          padding: 14px 100px;
          color: #fff;
          font-size: 20.92px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;
          display: block;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .countSection {
    .col-md-4 {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .banner-main .votesDiv {
    margin-top: 25px;

    p {
      margin-bottom: 20px;
    }
  }

  .banner-main .countSection .forAganist {
    // border: 5px solid #2E2E2E;
    box-shadow: 0px 40px 39px -30px rgba(0, 0, 0, 0.25);
    background-color: transparent;
    border-radius: 5px;
    padding: 43px 0px;
    text-align: center;
    margin: 20px 0px;
    position: relative;
    background: linear-gradient(
        103deg,
        rgba(255, 160, 198, 0.15) 0%,
        rgba(126, 126, 126, 0.01) 110.97%
      ),
      rgba(29, 29, 29, 0.2);

    -webkit-backdrop-filter: blur(35.1px);
    backdrop-filter: blur(35.1px);

    &:after {
      content: "";
      position: absolute;
      inset: 0px;
      border-radius: inherit;
      background-color: #ffffff;
      -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box,
        -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      border: 1.7px solid transparent;
      background: linear-gradient(
          103deg,
          rgba(255, 160, 198, 0.15) 0%,
          rgba(126, 126, 126, 0.01) 110.97%
        ),
        rgba(29, 29, 29, 0.2);

      background: linear-gradient(#111111 0 0) padding-box,
        linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%)
          border-box;
      z-index: -1;
    }
  }
}

.read-article-paragraph {
  h1 {
    color: #fff;
  }

  h2 {
    color: #fff;
  }

  span {
    color: #afafaf !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    background-color: unset !important;
    line-height: 150%;
    /* 24px */
  }

  li {
    color: #afafaf !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    background-color: unset !important;
  }

  p {
    span {
      text-transform: capitalize !important;
      color: #afafaf !important;
      background: unset !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 150%;
      /* 24px */
      text-transform: uppercase;
    }
  }

  img {
    max-width: 750px !important;
    width: 100% !important;
    object-fit: contain !important;
    // object-fit: contain;
  }
}

@media (max-width: 600px) {
  .read-article-paragraph {
    overflow: hidden;
  }

  .mainnnny {
    flex-direction: column !important;
  }

  .syuvsgdi7s {
    p {
      white-space: nowrap;
      width: 340px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .banner-main .votesDiv p {
    margin-bottom: 0px;
  }

  .banner-main .countSection .forAganist h3 {
    font-size: 35px;
  }

  .banner-main .countSection .forAganist p {
    font-size: 18px;
  }

  .exii {
    margin-bottom: 22px !important;
  }

  .banner-main .countSection {
    padding: 35px 0px;
  }

  .banner-main .topBtnsDiv .textbtn {
    justify-content: space-between;
  }

  .banner-main .topBtnsDiv .randomtext {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .banner-main .topBtnsDiv .randomtext .texted p {
    text-align: start;
  }

  .banner-main .topBtnsDiv .randomtext .votesDiv p {
    text-align: start;
  }

  .detail-table .text h6 {
    word-break: break-all;
  }
}

@media (max-width: 409px) {
  .banner-main .votesDiv p {
    margin-bottom: 20px;
  }
}

@media (max-width: 393px) {
  .banner-main button {
    // background: #4965f0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 9px 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    outline: none;
    height: 100%;
    margin-bottom: 7.5px;
  }
}

.read-article-paragraph {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 150%;
  text-transform: unset !important;
  color: #afafaf;
  text-align: left;

  ul {
    list-style: unset !important;
  }

  p {
    text-align: left !important;

    // span {

    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 150%;
    //     text-transform: uppercase;
    //     color: #fff !important;
    //     text-align: left;
    //     background-color: transparent !important;
    // }
  }
}

.ashdgaschgxc {
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #ff0083 !important;
}

.wait {
  cursor: wait !important;
}

.banner-main .comments .commentstextarea p {
}

.banner-main .comments .comments-heading h2 {
}

.detail-table {
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;
  padding: 25px;
  border: 5px solid #2e2e2e;
  background-color: transparent;
  box-shadow: 0px 40px 39px -30px rgba(0, 0, 0, 0.25);
  position: relative;
  $border: 1.5px;
  color: #fff;
  background: #2b2326;
  background-clip: padding-box;
  border: solid $border transparent;
  border-radius: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background: var(--gradient, linear-gradient(180deg, #ff0083 0%, #171717 103.07%));
  }

  .heading {
    margin-bottom: 20px;

    h4 {
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 110%;
      text-align: start;
      color: #fff;
      text-align: center;
    }
  }

  p {
    margin: 0 !important;
  }

  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1.5px solid #f3f3f3;

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      color: #fff;
      text-align: center;
    }
  }
}
